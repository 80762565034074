import styled from 'styled-components';
import { Heading, Link } from 'components';
import { font } from 'style';
import { Card } from './Card';

export default {
  Container: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  `,
  SubTitle: styled(Heading)`
    margin-top: 10px;
    text-align: center;
  `,
  CardContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 35px;
    margin-top: 30px;

    @media only screen and (min-width: 700px) {
      grid-template-columns: 1fr 1fr;
    }
  `,
  Card: styled(Card)``,
  FirstTermTitle: styled(Heading)`
    margin-top: 30px;
    text-align: center;
    font-weight: ${font.weight.bold};
  `,
  FirstTermDescription: styled.p`
    margin-top: 40px;
    width: 100%;
    max-width: 850px;
    text-align: center;
  `,
  FirstTermImg: styled.img`
    width: 100%;
    margin-top: 30px;
  `,
  Contact: styled.p`
    margin-top: 50px;
    text-align: center;
    line-height: 25px;
  `,
  Link: styled(Link)`
    font-weight: ${font.weight.bold};
  `,
};
