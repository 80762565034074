import { Layout, Footer } from 'components';
import { HeaderSection, MainSection } from 'page-components/home';
import { color } from 'style';

const IndexPage = () => {
  return (
    <Layout meta={{ description: 'Για μια κοινωνία ισότιμων ανθρώπων.' }}>
      <HeaderSection />
      <MainSection />
      <Footer backgroundColor={color.hmPurple1} />
    </Layout>
  );
};

export default IndexPage;
