import logoImage from 'assets/images/PB-logo.svg';
import S from './HeaderSection.Styled';

export const HeaderSection = () => {
  return (
    <S.Header>
      <S.Container>
        <S.Image src={logoImage} alt="Equall Λογότυπο" />
      </S.Container>
    </S.Header>
  );
};
