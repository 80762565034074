import { FC } from 'react';
import { InnerLink } from 'components';

import S from './Card.Styled';

interface CardProps {
  className?: string;
  logoImage: string;
  image: string;
  link: string;
  alt: string;
}

export const Card: FC<CardProps> = ({ className, logoImage, image, link, alt }) => {
  return (
    <InnerLink to={link}>
      <S.Card className={className}>
        <S.Header>
          <S.ImgWrapper>
            <S.Img src={image} alt={alt} />
          </S.ImgWrapper>
          <S.LogoImg src={logoImage} alt="Equall Λογότυπο" />
        </S.Header>
        <S.More>Περισσoτερα</S.More>
      </S.Card>
    </InnerLink>
  );
};
