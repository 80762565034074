import { Section } from 'components';
import { font } from 'style';
import logoWFAMImage from 'assets/images/WFAM-logo-colored.svg';
import wfamImg from 'assets/images/home-card-women-founders-and-makers.svg';
import logoWBWImage from 'assets/images/WBW-logo-colored.svg';
import wbwImg from 'assets/images/home-card-women-back-to-work.svg';
import logoAgroImage from 'assets/images/Agro-logo-colored.svg';
import agroImg from 'assets/images/home-agro.svg';
import logoPHNGImage from 'assets/images/PHNG-logo-colored.svg';
import phngImg from 'assets/images/home-card-profession-has-no-gender.svg';
import WomanHeroImg from 'assets/images/home-woman-hero.svg';

import S from './MainSection.Styled';

const cards = [
  {
    logoImage: logoWFAMImage,
    image: wfamImg,
    link: '/womenfoundersandmakers',
    alt: 'Απασχολημένη γυναίκα επιχειρηματίας',
  },
  {
    logoImage: logoWBWImage,
    image: wbwImg,
    link: '/womenbacktowork',
    alt: 'Εργαζόμενη γυναίκα',
  },
  {
    logoImage: logoAgroImage,
    image: agroImg,
    link: '/womeninagriculture',
    alt: 'Αγρότισσα',
  },
  {
    logoImage: logoPHNGImage,
    image: phngImg,
    link: '/professionhasnogender',
    alt: 'Iσότητα των φύλων στον επαγγελματικό χώρο',
  },
];

export const MainSection = () => {
  return (
    <Section>
      <S.Container>
        <S.SubTitle fontSize={font.size.large}>
          Η Τράπεζα Πειραιώς σε συνεργασία με τους 100mentors και το The Tipping Point δημιούργησαν τις δράσεις:
        </S.SubTitle>
        <S.CardContainer>
          {cards.map((card) => (
            <S.Card key={card.link.toLowerCase()} {...card} />
          ))}
        </S.CardContainer>
        <S.FirstTermDescription>
          Σκοπός των δράσεων είναι η ενίσχυση και υποστήριξη των γυναικών, καθώς και η ευαισθητοποίηση της νέας γενιάς
          για μια κοινωνία ισότιμων ανθρώπων.
        </S.FirstTermDescription>
        <S.FirstTermImg src={WomanHeroImg} alt="Γυναίκα Σουπερήρωας" />
        <S.Contact>
          Για οποιαδήποτε απορία μπορείτε να επικοινωνήσετε μαζί μας στο email:
          <br />
          <S.Link href="mailto:support@100mentors.com">support@100mentors.com</S.Link>
        </S.Contact>
      </S.Container>
    </Section>
  );
};
