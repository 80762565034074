import styled from 'styled-components';
import { Header } from 'components';
import { font } from 'style';

export default {
  Header: styled(Header)``,
  Container: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  `,
  Image: styled.img`
    max-width: 300px;
    width: 100%;
  `,
  Description: styled.div`
    width: 100%;
    max-width: 900px;
    margin-top: 15px;
    text-align: left;
    font-family: ${font.family.roboto};
    font-size: 22px;
    font-weight: 300;
    text-align: center;
    line-height: 31px;
  `,
};
