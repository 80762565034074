import styled from 'styled-components';
import { Heading } from 'components';
import { color, font } from 'style';

export default {
  Card: styled.div`
    /* width: 265px; */
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      transform: translateY(-10px);
    }

    transition: transform 0.35s ease;
  `,
  Header: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  `,
  ImgWrapper: styled.div`
    background-color: ${color.hmPurple2};
    max-width: 245px;
    width: 100%;
    height: 245px;
    padding: 0px 15px;
  `,
  Img: styled.img`
    width: 100%;
    height: 100%;
  `,
  LogoImg: styled.img`
    width: 100%;
    margin-top: 20px;
  `,
  Title: styled(Heading)`
    margin-top: 10px;
    text-align: center;
  `,
  More: styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: ${color.hmBlue};
    font-weight: ${font.weight.bold};
  `,
};
